<template>
  <div class="error-404">
    <div class="error-404__content">
      <div class="animated hinge">
        <i class="icon-error fa fa-skull-crossbones fa-5x"></i><br />
        <h1><b>Error 404</b></h1>
        <h3>Page not found</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style lang="scss" scoped>
.error-404 {
  &__content {
    @include justify-items-center;
    margin-top: 150px !important;
    margin-bottom: 100px !important;

    .icon-error {
      color: $color-user-busy;
    }

    .animated {
      animation-duration: 6s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
    }

    .hinge {
      margin: 20px;
      animation-name: hinge;
    }
  }
}

@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  80% {
    transform: rotate(60deg) translateY(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateY(300px);
  }
}
</style>
